import React, { useState } from 'react';
import {  Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Dropdown from "react-bootstrap/Dropdown";
import HelpIcon from "../../../images/icon_help.gif";
import PearsonLogo from '../../../images/logo.gif';



const TopNav = (props) => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams]  = useSearchParams();
    const locationpathname = (location.pathname).replace('/mdc/auth/media','');
    const backButtonLocationPath = (location.pathname).replace('/v1','');

    const [showDropdownItems, setShowDropdownItems] = useState(false);
    const handleLogout = (logoutType) => {
        if (logoutType === "redirect") {
            localStorage.removeItem("accessToken");
            localStorage.clear();
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
          }
    };
    const toggleDropdown = () => {
        setShowDropdownItems(!showDropdownItems); 
    };
    let userName ='';
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (/^.+-login\.windows\.net-\d.+$/.test(key)){
            const tokenlist = localStorage.getItem(key);
            const tokenvalue = JSON.parse(tokenlist);
            userName= tokenvalue.idTokenClaims.name;
            break; 
        }
    }

    return (
      <>
       <table border="0" width="99%" cellSpacing="3" cellPadding="0">
                <tr align="left" valign="top">
                    <td align="left" valign="middle"><span className="pagetitle">{props.title}</span></td>
                    <td align="right" valign="middle">

                        <table border="0" cellSpacing="0" cellPadding="0" align="right">
                            <tr align="left" valign="top">
                            { searchParams.get("help") == 1 &&
                                <td align="right" valign="middle" nowrap><Link to={ backButtonLocationPath} 
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`${backButtonLocationPath}`);
                                }}
                                class="helpLink" title="back">back</Link>&nbsp;</td>
                            }
                            { searchParams.get("help") != 1 &&
                                <td align="right " valign="middle" nowrap="true"><Link to={{ pathname : `/mdc/auth/media/v1${locationpathname}`, search: '?help=1'}} onClick={(e) => {
                                        e.preventDefault();
                                        navigate(`/mdc/auth/media/v1${locationpathname}?help=1`);
                                    }} className="helpLink" title="Need Help?">Need Help</Link>&nbsp;</td>
                            }
                                <td align="left" valign="middle"><Link  to="?help=1"  title="Need Help?"><img src={HelpIcon} alt="Need Help?" title="Need Help?" width="25" height="25" hspace="4" vspace="0" border="0" /></Link></td>
                            </tr>
                        </table>

                    </td>
                     <td align="right" className="logout-td" valign="middle" width="5%">
                        <Dropdown>
                            <Dropdown.Toggle as={Dropdown.Text} variant="secondary" className="drop-down" drop="start" onClick={toggleDropdown}>
                                 {userName}
                             </Dropdown.Toggle>
                             {showDropdownItems && (
                                <Dropdown.Item as="button" className="drop-item" onClick={() => handleLogout("redirect")}>
                                 Logout
                                 </Dropdown.Item>
                             )}
                         </Dropdown>

                     </td>
                    <td align="right" valign="middle" width="5%"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="44" height="26" hspace="0" vspace="0" border="0"/></td>
                </tr>
            </table>

            
    </>
  )
}

export default TopNav;