import React from 'react';
import '../Modal/modal.css';
import PearsonLogo from '../../../images/logo.gif';


const NoUserDir = ({isOpen, onRequestClose, data}) => {

    const onModalpopupClose = (e) =>{
		onRequestClose(isOpen);
	}

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
     };

    return(
        <div className="modal-content-table" > 

            <table border="0" width="99%" cellSpacing="3" cellPadding="0" >
	            <tr align="left" valign="top">
                <td align="left" valign="middle"><span class="pagetitle">&nbsp;No User/Dir Found</span></td>
	            	<td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="44" height="26" hspace="0" vspace="0" border="0"/></td>
                    <td><span 
                            className="close"
                             onClick={onModalpopupClose}>&times;
                        </span>
                    </td>
	            </tr>
           	</table>

            <div id="table" className='unzip-table'>
                <br/>
                <br/>
                <table border="0" width="99%" cellSpacing="0" cellPadding="0">
                    { data?.message?.includes('directory is not in accessiable List') &&
                        <tr align="left" valign="top">
                            <td align="center" valign="middle"class="instructions">
                            You don't have permission to access to the Media Server Console,
                            <button className='no-user-dir-button' onClick={() => openInNewTab("https://pearsonnow.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=9336da491b8ea1d01c87ed79b04bcb85&sysparm_role_access=f303af0c1b25f5509c7cbb35464bcb2c")}>please complete this form to request access  
                                </button>
                            </td>
                        </tr>
                    }
                    { data?.message?.includes("No user found") &&
                        <tr align="left" valign="top">
                            <td align="center" valign="middle"class="instructions">
                            You don't have permission to access this Media Server Console directory,
                            <button className='no-user-dir-button' onClick={() => openInNewTab("https://pearsonnow.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=9336da491b8ea1d01c87ed79b04bcb85&sysparm_role_access=f303af0c1b25f5509c7cbb35464bcb2c")}>please complete this form to request access  
                                </button>
                            </td>
                        </tr>
                    }
                    { data?.message?.includes("Please contact Adminstrator, User is Inactive") &&
                        <tr align="left" valign="top">
                            <td align="center" valign="middle"class="instructions">
                                {data?.message}
                            </td>
                        </tr>
                    }
                </table>
                <br/>
                <table width="50%" border="0" cellSpacing="0" cellPadding="0" align="center">
                    <tr align="center">
                        <td align="center" valign="middle"><input name="" type="button" class="unzip-formButton" value="&nbsp;Cancel&nbsp;" onClick={onModalpopupClose}/></td>
                        
                </tr>
                </table>
            </div>
        </div>
    );

}

export default NoUserDir