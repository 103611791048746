import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import '../../Modal/modal.css';
import PearsonLogo from '../../../../images/logo.gif';
import { renameFolderAction } from './actions';
import renameButton from '../../../../images/icon_rename.gif';


const RenameFolder = ({isOpen, onRequestClose, currentPath, renamefolder}) => {

    const dispatch = useDispatch();
    const [folderName, setFolderName] = useState(renamefolder);
    const [renameFolderErrMsg, setRenameFolderErrMsg] = useState('');
	const [searchParams, setSearchParams] = useSearchParams();
    const onModalpopupClose = (e) =>{
		if (searchParams.has('rename')) {
            searchParams.delete('rename');
            setSearchParams(searchParams);
        }
		onRequestClose(isOpen);
	}

    const renameFolder = e => {
        e.preventDefault();
        if(renamefolder == folderName)
        {
            alert('You have not made any changes');
            return;
        }
        if(folderName != undefined && folderName !='')
        {
            dispatch(renameFolderAction({data : {'current_path' : currentPath, 'rename_filename' : renamefolder, rename_filename_new : folderName}}));
            onModalpopupClose();
        }else{
            alert('Folder name cannot be empty')
            setFolderName(renamefolder)
        }
    }

    const updateFolderName = e => {
        e.preventDefault();
        const notAllowedRegex = /[%+\/\\]+/;
        if (e.target.value.includes(' ')) {
            setRenameFolderErrMsg('Spaces are not allowed in file or folder name.');
            return;
        }else{
            setRenameFolderErrMsg('');
        }
        if (notAllowedRegex.test(e.target.value)) return
 
        setFolderName(e.target.value);
    }

    const handleSpecialChar = event => {
        
        // Not allowed special characters in folder name
        if(event.key === '/' || event.key === '\\' ||  event.key === '%' || event.key === '+')
        {
            alert(`${event.key} specical character is not allowed in folder name`);
        }
        
    }

    return(
        <div className="modal-content-table" > 

            <table border="0" width="99%" cellSpacing="3" cellPadding="0" >
	            <tr align="left" valign="top">
                <td align="left" valign="middle"><span class="pagetitle">&nbsp;Rename Folder</span></td>
	            	<td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="44" height="26" hspace="0" vspace="0" border="0"/></td>
                    <td><span 
                            className="close"
                             onClick={onModalpopupClose}>&times;
                        </span>
                    </td>
	            </tr>
           	</table>
            <div id="background" className='create-new-folder'>

            </div>
            

            <div id="table" className='unzip-table'>
                <table border="0" width="99%" cellSpacing="0" cellPadding="0">
                    <tr align="left" valign="top">
                        <td align="left" valign="top" width="90"><img src={renameButton} alt="New Folder" width="90" height="68" hspace="0" vspace="0" border="0"/></td>
                        <td align="center" valign="middle"class="instructions">
                            <span>
                                To rename a file or folder, enter the new name below and click "Rename".
                            </span>
                            <br/>
                            <input type="text" size="24"  name="newfolder_name" className='create-new-folder-input' value = {folderName} onChange = { updateFolderName } onKeyPress = { handleSpecialChar }/>
                            <br/>
                            <table width="50%" border="0" cellSpacing="0" cellPadding="0" align="center">
                                <tr align="center">
                                    <td align="center" valign="middle"><input name="" type="button" class="unzip-formButton" value="&nbsp;Cancel&nbsp;" onClick={onModalpopupClose}/></td>
                                    <td align="center" valign="middle"><input name="" type="button" class="unzip-formButtonGo" value="&nbsp;&nbsp;Rename&nbsp;&nbsp;" onClick = {renameFolder}/></td>
                                </tr>
                            </table>
                            <br/>
                            <span className='create-new-folder-ErrorMsg'>
                                {renameFolderErrMsg}
                            </span>
                        </td>
	                </tr>
                </table>
            </div>
        </div>
    );

}

export default RenameFolder