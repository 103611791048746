import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import '../../Modal/modal.css';
import PearsonLogo from '../../../../images/logo.gif';
import { createNewFolderAction } from './actions';
import IconNewFolder from "../../../../images/icon_new_folder.gif";


const NewFolder = ({isOpen, onRequestClose, currentPath}) => {

    const dispatch = useDispatch();
    const [folderNameErrMsg, setFolderNameErrMsg] = useState('');
    const [folderName, setFolderName] = useState('');
	const [searchParams, setSearchParams] = useSearchParams();
    const onModalpopupClose = (e) =>{
		if (searchParams.has('newfolder')) {
            searchParams.delete('newfolder');
            setSearchParams(searchParams);
        }
		onRequestClose(isOpen);
	}

    const cancelCreateNewFolder = (e) => {
        onRequestClose(isOpen)
    }

    const makeFolder = e => {
        e.preventDefault();
        dispatch(createNewFolderAction({'current_path' : currentPath, 'folder_name' : folderName}));
        onModalpopupClose();
    }

    const updateFolderName = e => {
        e.preventDefault();
        const notAllowedRegex = /[%+\/\\]+/;
        if (e.target.value.includes(' ')) {
            setFolderNameErrMsg('Spaces are not allowed in folder name.');
            return;
        }else{
            setFolderNameErrMsg('');
        }
        if (notAllowedRegex.test(e.target.value)) return
        setFolderName(e.target.value);
    }

    const handleSpecialChar = event => {
        
        // Not allowed special characters in folder name
        if(event.key === '/' || event.key === '\\' ||  event.key === '%' || event.key === '+')
        {
            alert(`${event.key} specical character is not allowed in folder name`);
        }
        
    }

    return(
        <div className="modal-content-table" > 

            <table border="0" width="99%" cellSpacing="3" cellPadding="0" >
	            <tr align="left" valign="top">
                <td align="left" valign="middle"><span class="pagetitle">&nbsp;Create New Folder</span></td>
	            	<td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="44" height="26" hspace="0" vspace="0" border="0"/></td>
                    <td><span 
                            className="close"
                             onClick={onModalpopupClose}>&times;
                        </span>
                    </td>
	            </tr>
           	</table>
            <div id="background" className='create-new-folder'>

            </div>
            

            <div id="table" className='unzip-table'>
                <table border="0" width="99%" cellSpacing="0" cellPadding="0">
                    <tr align="left" valign="top">
                        <td align="left" valign="top" width="90"><img src={IconNewFolder} alt="New Folder" width="90" height="68" hspace="0" vspace="0" border="0"/></td>
                        <td align="center" valign="middle"class="instructions">
                            <span>
                                To create a new folder, enter the name below and click "Create Folder".
                            </span>
                            <br/>
                            <input type="text" size="24"  name="newfolder_name" className='create-new-folder-input' value = {folderName} onChange = { updateFolderName } onKeyPress = { handleSpecialChar }/>
                            <br/>
                            <table width="50%" border="0" cellSpacing="0" cellPadding="0" align="center">
                                <tr align="center">
                                    <td align="center" valign="middle"><input name="" type="button" class="unzip-formButton" value="&nbsp;Cancel&nbsp;" onClick={cancelCreateNewFolder}/></td>
                                    <td align="center" valign="middle"><input name="" type="button" class="unzip-formButtonGo" value="&nbsp;&nbsp;Create Folder&nbsp;&nbsp;" onClick = {makeFolder}/></td>
                                </tr>
                            </table>
                            <br/>
                            <span className='create-new-folder-ErrorMsg'>
                                {folderNameErrMsg}
                            </span>
                        </td>
	                </tr>
                </table>
            </div>
        </div>
    );

}

export default NewFolder