import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import '../../Modal/modal.css';
import PearsonLogo from '../../../../images/logo.gif';
import { unzipAction } from './actions';
import icon_unzip from '../../../../images/icon_unzip.gif';


const Unzip = ({isOpen, onRequestClose, currentPath, unzipFile}) => {

    const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
    const onModalpopupClose = (e) =>{
		if (searchParams.has('unzip')) {
            searchParams.delete('unzip');
            setSearchParams(searchParams);
        }
		onRequestClose(isOpen);
	}

    const cancelUnzipModal = (e) => {
        onRequestClose(isOpen)
    }

    const unzipFolder = e => {
        dispatch(unzipAction({'current_path' : currentPath, 'unzipfile' : unzipFile}));
        onModalpopupClose();
    }

    return(
        <div className="modal-content-table" > 

            <table border="0" width="99%" cellSpacing="3" cellPadding="0" >
	            <tr align="left" valign="top">
                <td align="left" valign="middle"><span class="pagetitle">&nbsp;Unzip File</span></td>
	            	<td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="44" height="26" hspace="0" vspace="0" border="0"/></td>
                    <td><span 
                            className="close"
                             onClick={onModalpopupClose}>&times;
                        </span>
                    </td>
	            </tr>
           	</table>

            <div id="table" className='unzip-table'>
                <table border="0" width="99%" cellSpacing="0" cellPadding="0">
                    <tr align="left" valign="top">
                        <td align="left" valign="top" width="90"><img src={icon_unzip} alt="Unzip" width="90" height="94" hspace="0" vspace="0" border="0"/></td>
                        <td align="center" valign="middle"class="instructions">Warning! You have chosen to unzip 
                        <font style={{color:'red'}}> {unzipFile} </font> in the <font style={{color:'red'}}>{currentPath}</font> directory. Unzipping this file will expand all files contained within this zip into this directory. Do you wish to proceed?</td>
                    </tr>
                </table>

                <table width="50%" border="0" cellSpacing="0" cellPadding="0" align="center">
                    <tr align="center">
                        <td align="center" valign="middle"><input name="" type="button" class="unzip-formButton" value="&nbsp;Cancel&nbsp;" onClick={cancelUnzipModal}/></td>
                        <td align="center" valign="middle"><input name="" type="button" class="unzip-formButtonGo" value="&nbsp;&nbsp;Unzip&nbsp;&nbsp;" onClick = {unzipFolder}/></td>
                </tr>
                </table>
            </div>
        </div>
    );

}

export default Unzip