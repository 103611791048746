import React, { useEffect, useState, useCallback }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from "react-router-dom";
import { uploadmediafiles } from './actions';
import {resetAppState} from './actions';
import '../Modal/modal.css';
import UploadIcon from '../../../images/icon_upload.gif';
import PearsonLogo from '../../../images/logo.gif';
import ProgressBarGif from '../../../images/prog_bar.gif';
import ProgressBarTopGif from '../../../images/prog_top.gif';
import ProgressBarLeftGif from '../../../images/prog_left.gif';


const Modal = props => {
     
    let divStyle = { 
        display: props.displayModal ? 'block' : 'none'
    };
    let dispatch = useDispatch();
    const navigate = useNavigate();
    let [selectedFile, setSelectedFile] = useState({});
    let [uploadedFileSize,setUploadedFileSize] = useState({});
    let [searchParams, setSearchParams] = useSearchParams(); 
    let [multipleFileProgress, setMultipleFileProgress] = useState(0); 
    let [ibytes,setIbytes] = useState(0);
    let [itotal,setITotal] = useState(0);
    let [uploadSubmitted, setUploadSubmitted] =useState(false);
    let [displayFileSection, setDisplayFileSection] =useState(false);
    let [successFileTableData,setSuccessFileTableData] = useState([]);
    let getMediaFileUploadedStatus = useSelector(state => { return state.fileUploadReducer.message });
    let [fileUploadStartTime,setFileUploadStartTime] = useState('');
    let [elapsedTime,setElapsedTime] = useState(0);
    let [netSpeed,setNetSpeed] = useState(0);
    let [reminainingTime, setRemainingTime] = useState('');


    function closeModal(e) {
    e.stopPropagation()
    props.onModalPopupStateChange(props.displayModal);
    if (searchParams.has('upload')) {
        searchParams.delete('upload');
        setSearchParams(searchParams);
        dispatch(resetAppState({message:""}));
    }
    }
    const onFileChange = (e) => {
    let file_size = (e.target.files[0].size/ (1024*1024)).toFixed(2);
    if(file_size > 50)
    {
        e.target.value = null;
        alert('Max file size exceeded');
        return;
    }
    setUploadedFileSize({ ...uploadedFileSize,[e.target.name]: file_size });
    setSelectedFile({ ...selectedFile, [e.target.name]: e.target.files[0] });
    }

    useEffect(() =>{
    if(getMediaFileUploadedStatus){
        
        if (searchParams.has('upload')) {
            //props.navigate(-1);
            navigate(-1);
            dispatch(resetAppState({}));
        }
        let sucessfileInfo = [];
        for (let index = 0; index < getMediaFileUploadedStatus.data.length; index++) {
                        
            let trColor = '';
            if(index % 2 === 0)
            {
                trColor = "backgroundColor:'#DDDDDD'";
            }
            sucessfileInfo.push(<tr align="left" key={index} style={{trColor}}>
                <td align="left" valign="middle" style={{border:"1px solid black"}}>{getMediaFileUploadedStatus.data[index].originalfilename}</td>
                <td align="center" valign="middle"  style={{border:"1px solid black"}}>{getMediaFileUploadedStatus.data[index].size}</td>
            </tr>);
        }
        setSuccessFileTableData(sucessfileInfo);
        setDisplayFileSection(true);
    }
    
    },[getMediaFileUploadedStatus]);
    
    var fieldsArray = [];
    for (let index = 1; index <= 8; index++) {
                        
    let fileName = "file["+index+"]";
    fieldsArray.push(<tr align="left" valign="top" key={index}>
        <td align="left" valign="middle" width="40"><span className="numbers">{index}</span></td>
        <td align="center" valign="middle"><input className="formButton" type="file" size="24" key={index} name={fileName} style={{width:"300px", height:"16px", lineHeight:"16px", fontFamily: "verdana", fontSize:"10px", paddingTop: "2px", paddingRight: "0px", paddingBottom: "0px", paddingLeft: "5px", marginTop: "0px", marginRight: "0px", marginBottom: "0px", marginLeft: "0px"}} onChange={onFileChange}/></td>
    </tr>);
    } 

    const uploadFiles =  async(e) => {
        e.preventDefault();
        let cummulativeFileSize = 0;
        Object.keys(uploadedFileSize).map((value,index) =>{
            cummulativeFileSize = parseFloat(cummulativeFileSize) + parseFloat(uploadedFileSize[value]);
        });
        if(cummulativeFileSize > 50)
        {
            alert('Max Upload file size of 50MB exceeded');
            return;
        }
        const formData = new FormData();
        formData.append('uploadPath',props.currentDir);
        if(Object.keys(selectedFile).length === 0)
        {
            alert('Please Select Files to upload');
            return;
        }
        Object.keys(selectedFile).map((key,index) => {
            
            formData.append(`file[]`, selectedFile[key], encodeURIComponent(selectedFile[key].name));
        });
        setFileUploadStartTime(new Date().getTime());
        dispatch(uploadmediafiles({'formdata':formData,'options':multipleFileOptions}));
        setUploadSubmitted(true);
    }

    const multipleFileOptions = {
        onUploadProgress: (progressEvent) => {
            let {loaded, total} = progressEvent;
            let percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000))+'%';
        
            setIbytes(loaded);
            setITotal(total);
            if(percentage === '100%')
            {
                // for UI Progress bar adjustment, taking max value as 99%, if it is 100% 
                percentage = '99%';
            }
            setMultipleFileProgress(percentage);
        },
        signal: props.controller.signal
    }

    const cancelUpload = useCallback((e) => {
        if(uploadSubmitted) {
            props.controller.abort();
        }
        closeModal(e);
        
    },[props.controller])

    useEffect(() =>{
            let uploadProgressTimeStamp = new Date().getTime();
            let elapsedTimeStamp = (parseFloat(uploadProgressTimeStamp) - parseFloat(fileUploadStartTime)) / 1000;

            let elapsedTimeInSec = (elapsedTimeStamp % 60 ); // get number of seconds
            let elapsedTimeInMin = (((elapsedTimeStamp - elapsedTimeInSec) % 3600) / 60 ); // get number of minutes
            let elapsedTimeInHrs = ((((elapsedTimeStamp - elapsedTimeInSec) - (elapsedTimeInMin * 60)) % 86400) / 3600 );
            //gets number of hours; assuming that we won't be going into days!
            if (elapsedTimeInSec < 10) { elapsedTimeInSec = `0${Math.ceil(elapsedTimeInSec)}`; } // append leading zero
            if (elapsedTimeInMin < 10) { elapsedTimeInMin = `0${elapsedTimeInMin}`; } // append leading zero
            if (elapsedTimeInHrs < 10) { elapsedTimeInHrs = `0${elapsedTimeInHrs}`; }// append leading zero
            let dtelapsedf = elapsedTimeInHrs +":"+ elapsedTimeInMin +":"+ elapsedTimeInSec; // display as 00:00:00

            setElapsedTime(dtelapsedf);
            let bSpeed = elapsedTimeStamp ? ibytes / elapsedTimeStamp : 0;
            let bitSpeed = bSpeed * 8; // bps
            let kbitSpeed = (bitSpeed / 1000).toFixed(2);
            if(kbitSpeed)
                setNetSpeed(kbitSpeed);
            

            let bytesRemainaining = itotal - ibytes;
            let dtRemaining = 0;

            if(kbitSpeed)
            {
                dtRemaining = bytesRemainaining / kbitSpeed;
            }
            dtRemaining = Math.trunc(dtRemaining);

            let dtRemainingInSec = (dtRemaining % 60 ); // get number of seconds
            let dtRemainingInMin = (((dtRemaining - dtRemainingInSec) % 3600) / 60 ); // get number of minutes
            let dtRemainingInHrs = ((((dtRemaining - dtRemainingInSec) - (dtRemainingInMin * 60)) % 86400) / 3600 );
            //gets number of hours; assuming that we won't be going into days!
            if (dtRemainingInSec < 10) { dtRemainingInSec = `0${Math.ceil(dtRemainingInSec)}`; } // append leading zero
            if (dtRemainingInMin < 10) { dtRemainingInMin = `0${dtRemainingInMin}`; } // append leading zero
            if (dtRemainingInHrs < 10) { dtRemainingInHrs = `0${dtRemainingInHrs}`; }// append leading zero
            let dtRemainingf = dtRemainingInHrs +":"+ dtRemainingInMin +":"+ dtRemainingInSec; // display as 00:00:00
            
            setRemainingTime(dtRemainingf);

    },[ibytes, fileUploadStartTime, itotal, reminainingTime]);
     return (
       <div 
         className="modal"
         onClick={ closeModal }
         style={divStyle}>
          <div 
             className="modal-content"
             onClick={ e => e.stopPropagation() } >
             <div style={{display : displayFileSection ? 'none' : 'block'}}>
             <table border="0" width="100%" cellSpacing="3" cellPadding="0" className='modal-content-table'>
                <tr align="left" valign="top">
                    <td align="left" valign="middle"><span className="pagetitle">&nbsp;File Uploader</span></td>
                    <td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="44" height="26" hspace="0" vspace="0" border="0"/></td>
                    <td><span 
                            className="close"
                            onClick={cancelUpload}>&times;
                        </span>
                    </td></tr>
                    </table>
            {/* <div id="background" style="position:absolute; left:0px; top:35px; width:92px; height:407px; z-index:0; background-color: #E9EAEC; layer-background-color: #E9EAEC; border: 1px none #000000;"></div> */}
             <div id="table" style={{position:"absolute", top:"126px", width:"550px", height:"120px", zIndex:"1"}}>
                 <table border="0" width="" cellSpacing="0" cellPadding="0">
                    <tr align="left" valign="top">
                        <td align="left" valign="top" width="90" style={{backgroundcolor: "#E9EAEC"}}><img src={UploadIcon} alt="Upload Files" width="91" height="70" hspace="0" vspace="0" border="0"/></td>
                        <td align="center" valign="middle"className="instructions">You may upload as many as eight files at once. The maximum combined file size must not exceed 50Mb. <br/><br/>
                            File(s) uploaded will be located in the following directory:<br/>
                            <font style={{color:'red'}}>{props.currentDir}</font>
                            <br/><br/>
                     <form onSubmit={uploadFiles}>
                    <table border="0" width="90%" cellSpacing="0" cellPadding="5">
                       
                        {fieldsArray}
                    </table>
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="center">
                        <tr align="center">
                            <td align="center" valign="middle"><input name="" type="submit" className="formButtonUploadGo" value="&nbsp;&nbsp;&nbsp;Upload Files&nbsp;&nbsp;&nbsp;"/></td>
                        </tr>
                    </table>
                    </form>
                    </td>
                    </tr>
                    </table>
                    </div>
                    <div>
                        <br/><br/>
                    {uploadSubmitted === true &&
                    // <tr>
                    //     <td colSpan={3} style={{align:'center'}}>
                        <table style={{border:'0',width:'100%', backgroundColor:"#E9EAEC",marginTop:"335px"}}>

                            <tr align="center" style={{verticalAlign:"top"}}>
                                <td align="center" style={{verticalAlign:"middle"}} className="pagetitle" colSpan="2">File Upload in Progress</td>
                            </tr>

                            <tr align="left" style={{verticalAlign:"top"}} >
                            <td align="left" style={{verticalAlign:"middle"}} colSpan="2">

                            <table summary="This table displays the progress bar" border="0" width='100%' cellSpacing="0" cellPadding="0" background-color="#FFFFFF">
                                <tr align="left" style={{verticalAlign:"top"}}>
                                    <td align="left" style={{verticalAlign:"top",paddingTop:"13px"}} rowSpan="3" width="3"><img src={ProgressBarLeftGif} alt="" width="3" height="16" hspace="0" vspace="0" border="0" colSpan="2"/></td>
                                    <td align="left" style={{verticalAlign:"top"}}><img src={ProgressBarTopGif} alt="" width={multipleFileProgress} height="4" hspace="0" vspace="0" border="0" style={{marginBottom:"-5px"}}/></td>
                                </tr>

                                <tr align="left" style={{verticalAlign:"top"}}>
                                    <td align="left" style={{verticalAlign:"top"}}><span><img src={ProgressBarGif} alt="" width={multipleFileProgress} height="10" hspace="0" vspace="0" border="0"/></span>
                                    {/* <span><img src={ProgressBarRightGif} alt="" style={{width:"2",height:"10",hspace:"0",vspace:"0",border:"0"}}/></span> */}
                                    </td>
                                </tr>

                                <tr align="center" style={{verticalAlign:"top"}}>
                                    <td align="center" style={{verticalAlign:"middle"}} className="instructions" colSpan="2">Status: {ibytes} bytes of {itotal} sent (at {netSpeed} Kbps)</td>
                                </tr>

                            </table>
                            <table border="0" width='100%' cellSpacing="0" cellPadding="0" >
                            <tr align="center" verticalAlign="top">
                                    <td align="left" style={{verticalAlign:"middle"}} className="instructions"><strong>Est Time left:&nbsp;</strong> {reminainingTime}</td>              
                                    <td align="right" style={{verticalAlign:"middle"}} className="instructions"><strong>Elapsed time:&nbsp;</strong>{elapsedTime}</td>
                                </tr>
                            </table>
                            </td>
                            </tr>
                            </table>
                    }
                
              </div>
              </div>
              <div style={{display : displayFileSection ? 'block' : 'none', fontSize:'110%', fontFamily:"inherit", position:"relative",width:"100%",height:"100%"}}>
              <table style={{border:"1px solid black",cellPadding:"5",width:"95%",align:"center",marginLeft:"auto",marginRight:"auto",marginTop:"25px"}}>
                <tr>
                <td colSpan="2" style={{backgroundColor:"#0066cc"}}>
                    <font style={{color:"white",size:"+1",align:"center"}}>Files Uploaded</font>
                </td>
                </tr>

                <tr  style={{backgroundColor:"#CCCFF3"}}>
                <td style={{fontSize: "110%",border:"1px solid black"}}>
                    <nobr>File Name</nobr>
                </td>

                <td style={{fontSize: "110%",align:"right",border:"1px solid black"}}>
                    <nobr>File size</nobr>
                </td>
                </tr>
                    {successFileTableData}
                </table>
                {/* <p> */}
                    <center> <button onClick={ closeModal } className="closeButton">close</button> </center>

                {/* </p> */}
                
                <div style={{bottom:"30px",width:"100%",position:"absolute"}}>
                <hr />
                <table align="center">
                <tr><td align="center">File Upload Complete</td></tr>
                    <tr>
                        <td>
                        Status: {ibytes} bytes of {itotal} sent (at {netSpeed} Kbps)<br />
                            Est time left: 00:00:00<br />
                            Elapsed time: {elapsedTime}<br />
                        </td>
                    </tr>
                </table>
                </div>
                </div>
          </div>
       </div>
     );
}
export default Modal;